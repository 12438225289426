import Workers from './views/workers'
import Patients from './views/patients'
import Profile from './views/profile'
import DoctorPatients from './views/patients/DoctorPatients'
import About from './views/about'
import Carousel from './views/carousel'
import Service from './views/service'
import Dashboard from './views/dashboard'

const routeItem = (title, path, component, icon, roles, isSite = false, show = true) => {
    return {
        title,
        path,
        component,
        icon,
        roles,
        isSite,
        show
    }
}

const routes = {
    dashboard:routeItem('ThreeSteps', '/', <Dashboard/>, <i className="pi pi-user"/>, null, false, false),
    profile:routeItem('Profil', '/profile', <Profile/>, <i className="pi pi-user"/>),
    workers:routeItem('İşçilər', '/workers', <Workers/>, <i className="pi pi-users"/>, [1]),
    patients:routeItem('Xəstələr', '/patients', <Patients/>, <i className="pi pi-calendar"/>),
    doctor_patients:routeItem('Xəstələr', '/patients/:id', <DoctorPatients/>, <i className="pi pi-calendar"/>),
    about:routeItem('Əlaqə', '/about', <About/>, <i className="pi pi-info-circle"/>, [1], true),
    carousel:routeItem('Karusel', '/carousel', <Carousel/>, <i className="pi pi-images"/>, [1], true),
    services:routeItem('Servislər', '/services', <Service/>, <i className="pi pi-clone"/>, [1], true)
}

const routeArr = Object.values(routes)
export {
    routes,
    routeArr
}
