import CustomSidebar from '../sidebar'
import {useEffect} from 'react'
import './index.scss'
import {useNavigate} from 'react-router-dom'
import Auth from '../../../api/auth'

const MainLayout = ({item}) => {
    const navigate = useNavigate()
    const token = localStorage.getItem('token')
    if (!token) {
        navigate('/login')
    }
    useEffect(() => {
        document.title = item.title
    }, [item.title])

    const fetchUser = async() => {
        const {user} = await Auth.getUser()
        localStorage.setItem('user', JSON.stringify(user))
    }

    useEffect(() => {
        fetchUser()
    }, [])

    return (
        <>
            {token && <CustomSidebar/>}
            <div className="container">
                {item.component}
            </div>
        </>
    )
}

export default MainLayout
