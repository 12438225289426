import axios from './index'

class Site {
    getInfo = () => axios.get('/about')
    updateInfo = data => axios.put('/about', data)
    getCarousel = () => axios.get('/carusel')
    addCarousel = data => axios.post('/carusel', data)
    deleteCarousel = id => axios.delete(`/carusel/${id}`)
    updateCarousel = data => axios.put(`/carusel/order`, data)
    getServices = () => axios.get('/services')
    addService = data => axios.post('/service', data)
    updateService = data => axios.put('/service', data)
    deleteService = id => axios.delete(`/service/${id}`)
}

export default new Site()
