import axios from './index'

class Users {
    getList = (params) => axios.get('/users', {params})
    create = data => axios.post('/user', data)
    update = data => axios.post('/users/update', data)
    updateStatus = data => axios.post('/users/status', data)
    updatePassword = data => axios.post('/users/update/password', data)
}

export default new Users()
