import {useState} from 'react'
import './index.scss'
import {routeArr} from '../../../routes'
import Avatar from '../../core/avatar'
import {NavLink, useNavigate} from 'react-router-dom'
import classNames from 'classnames'
import text from '../../../data/text.json'
import Auth from '../../../api/auth'

const CustomSidebar = () => {
    const [actvie, setActive] = useState(true)
    const [isLogouting, setIsLogouting] = useState(false)
    const user = JSON.parse(localStorage.getItem('user'))
    const lang = 'az'
    const navigate = useNavigate()

    const logout = async() => {
        setIsLogouting(true)
        try {
            await Auth.logout()
            localStorage.clear()
            navigate('/login')
        } catch (e) {
            console.log('Error')
        } finally {
            setIsLogouting(false)
        }
    }

    return (
        <div className={classNames({
            sidebar:true,
            active:actvie
        })}>
            <div className="avatar-container" title={user?.email}>
                <Avatar user={user} id="logo"/>
            </div>
            <div className="toggle-sidebar" onClick={() => setActive(!actvie)}>
                <i className={classNames({
                    pi:true,
                    'text-white':true,
                    'pi-chevron-left':actvie,
                    'pi-chevron-right':!actvie
                })}/>
            </div>
            <nav>
                <p className="sub-nav-title">{text[lang].admin}</p>
                <ul className="menu">
                    {routeArr.filter(item => !item?.isSite).map(item => (
                        Array.isArray(item.roles) && !item.roles.includes(user?.role) || item.path.includes('/:id') ? null : (
                            item?.show && (
                                <li key={item.path}>
                                    <NavLink to={item.path}>
                                        {item.icon}
                                        <span>{item.title}</span>
                                    </NavLink>
                                </li>
                            )
                        )
                    ))}
                </ul>
                {user?.role === 1 && <p className="sub-nav-title">{text[lang].site}</p>}
                <ul className="menu">
                    {routeArr.filter(item => item?.isSite).map(item => (
                        Array.isArray(item.roles) && !item.roles.includes(user?.role) || item.path.includes('/:id') ? null : (
                            <li key={item.path}>
                                <NavLink to={item.path}>
                                    {item.icon}
                                    <span>{item.title}</span>
                                </NavLink>
                            </li>
                        )
                    ))}
                </ul>
                <div className="logout" onClick={logout}>
                    <i className={classNames({
                        pi:true,
                        'pi-sign-out' : !isLogouting,
                        'pi-spin pi-spinner' : isLogouting
                    })}/>
                    <span>{text[lang].logout}</span>
                </div>
            </nav>
        </div>
    )
}

export default CustomSidebar
