import './index.scss'
import Avatar from '../../components/core/avatar'
import {InputText} from 'primereact/inputtext'
import {Controller, useFieldArray, useForm} from 'react-hook-form'
import text from '../../data/text.json'
import {useEffect, useState} from 'react'
import {Dropdown} from 'primereact/dropdown'
import {Button} from 'primereact/button'
import {InputTextarea} from 'primereact/inputtextarea'
import {Calendar} from 'primereact/calendar'
import Auth from '../../api/auth'
import Select from '../../api/select'
import moment from 'moment'
import {ProgressSpinner} from 'primereact/progressspinner'
import classNames from 'classnames'

const Profile = () => {
    const user = JSON.parse(localStorage.getItem('user'))
    const [loader, setLoader] = useState(false)
    const [isFetching, setIsFetching] = useState(false)
    const [duties, setDuties] = useState([])
    const prefixElement = prefix => {
        return {
            value : prefix,
            label : prefix
        }
    }

    const prefixes = [
        prefixElement('050'),
        prefixElement('051'),
        prefixElement('055'),
        prefixElement('070'),
        prefixElement('077'),
        prefixElement('099'),
        prefixElement('010'),
        prefixElement('060')
    ]
    const {control, handleSubmit, formState:{errors}}  = useForm({
        defaultValues:{
            ...user,
            birth:new Date(user?.birth),
            educations:user?.educations?.length ? user?.educations : [{
                years:null,
                body:null
            }],
            works:user?.works?.length ? user?.works : [{
                years:null,
                body:null
            }]
        }
    })

    const educationArray = useFieldArray({
        control,
        name: 'educations'
    })

    const worksArray = useFieldArray({
        control,
        name: 'works'
    })

    const [profile, setProfile] = useState(null)
    const lang = 'az'

    const update = async data => {
        setLoader(true)
        try {
            const localData = {...data}
            delete localData.photo
            delete localData.birth
            delete localData.educations
            delete localData.works
            const formData = new FormData()
            if (profile) {
                formData.append('photo', profile)
            }
            Object.keys(localData).forEach(item => {
                formData.append(item, data[item])
            })
            formData.append('educations', JSON.stringify(data.educations))
            formData.append('works',  JSON.stringify(data.works))

            formData.append('birth', moment(data?.birth).format('YYYY-MM-DD'))
            const res = await Auth.userUpdate(formData)
            localStorage.setItem('user', JSON.stringify(res))
        } catch (e) {
            return e
        } finally {
            setLoader(false)
        }
    }

    const roles = [
        {
            value:1,
            label:text[lang].admin
        },
        {
            value:2,
            label:text[lang].doctor
        },
        {
            value:3,
            label:text[lang].reception
        }
    ]

    const changeProfile = e => {
        const files = e.target.files
        if (files.length) {
            setProfile(files[0])
        }
    }

    const fetchDuties = async() => {
        setIsFetching(true)
        const data = await Select.getDuties()
        setDuties(data)
        setIsFetching(false)
    }

    useEffect(() => {
        fetchDuties()
    }, [])
    return (
        <div className="profile-page">
            {isFetching ? (
                <div className="flex mt-5 justify-content-center align-items-center">
                    <ProgressSpinner/>
                </div>
            ) : (
                <div className="content w-full">
                    <p className="page-title">{text[lang].profile_page_title}</p>
                    <div className="avatar-container">
                        <label className="change-profile" htmlFor="profile-input">
                            <Avatar user={{
                                ...user,
                                photo:profile ? URL.createObjectURL(profile) : user?.photo
                            }}/>
                            <span className="change-icon">
                                <i className="pi pi-pencil"/>
                            </span>
                            <input type="file" name="profile-input" accept=".png,.jpg,.jpeg,.svg" id="profile-input" onChange={changeProfile}/>
                        </label>
                    </div>
                    <form className="mt-5" onSubmit={handleSubmit(update)}>
                        <div className="grid w-full">
                            <Controller rules={{required:true}} control={control} render={({field:{value, onChange}}) => (
                                <div className="col-12 md:col-4">
                                    <label htmlFor="name">{text[lang].name}</label>
                                    <InputText
                                        className="w-full"
                                        value={value}
                                        onChange={onChange}
                                        id="name"
                                        aria-describedby="name"
                                        placeholder={text[lang].name}
                                    />
                                    {errors.name && (
                                        <small className="form-element-is-invalid">
                                            {text[lang].field_required}
                                        </small>
                                    )}
                                </div>
                            )} name="name"/>
                            <Controller rules={{required:true}} control={control} render={({field:{value, onChange}}) => (
                                <div className="col-12 md:col-4">
                                    <label htmlFor="surname">{text[lang].surname}</label>
                                    <InputText
                                        className="w-full"
                                        value={value}
                                        onChange={onChange}
                                        id="surname"
                                        aria-describedby="surname"
                                        placeholder={text[lang].surname}
                                    />
                                    {errors.surname && (
                                        <small className="form-element-is-invalid">
                                            {text[lang].field_required}
                                        </small>
                                    )}
                                </div>
                            )} name="surname"/>
                            <Controller control={control} render={({field:{value, onChange}}) => (
                                <div className="col-12 md:col-4">
                                    <label htmlFor="father">{text[lang].father_name}</label>
                                    <InputText
                                        className="w-full"
                                        value={value}
                                        onChange={onChange}
                                        id="father"
                                        aria-describedby="father"
                                        placeholder={text[lang].father}
                                    />
                                    {errors.father && (
                                        <small className="form-element-is-invalid">
                                            {text[lang].field_required}
                                        </small>
                                    )}
                                </div>
                            )} name="father"/>
                        </div>
                        <div className="grid w-full md:mt-5 mt-0">
                            <div className="col-12 md:col-4">
                                <label htmlFor="phone">{text[lang].mobile_phone}</label>
                                <div className="w-full flex gap-1">
                                    <Controller control={control} render={({field:{value, onChange}}) => (
                                        <Dropdown
                                            value={value}
                                            onChange={onChange}
                                            options={prefixes}
                                            name="phone_prefix"
                                            className="w-14" />
                                    )} name="phone_prefix"/>
                                    <div className="w-full">
                                        <Controller render={({field:{value, onChange}}) => (
                                            <InputText
                                                className="w-full"
                                                value={value}
                                                onChange={onChange}
                                                id="phone"
                                                aria-describedby="phone"
                                                placeholder={text[lang].mobile_phone}
                                            />
                                        )} name="phone" control={control}/>
                                        {errors.phone && (
                                            <small className="form-element-is-invalid">
                                                {text[lang].field_required}
                                            </small>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <Controller rules={{required:true}} control={control} render={({field:{value, onChange}}) => (
                                <div className="col-12 md:col-4">
                                    <label htmlFor="email">{text[lang].email}</label>
                                    <InputText
                                        className="w-full"
                                        value={value}
                                        onChange={onChange}
                                        id="email"
                                        aria-describedby="email"
                                        placeholder={text[lang].email}
                                    />
                                    {errors.email && (
                                        <small className="form-element-is-invalid">
                                            {text[lang].field_required}
                                        </small>
                                    )}
                                </div>
                            )} name="email"/>
                            <Controller control={control} render={({field:{value, onChange}}) => (
                                <div className="col-12 md:col-4">
                                    <label htmlFor="duty_id">{text[lang].position}</label>
                                    <Dropdown
                                        value={value}
                                        onChange={onChange}
                                        options={duties}
                                        className="w-full"
                                        name="duty_id"/>
                                    {errors.duty_id && (
                                        <small className="form-element-is-invalid">
                                            {text[lang].field_required}
                                        </small>
                                    )}
                                </div>
                            )} name="duty_id"/>
                        </div>
                        <div className="grid md:mt-5 mt-0">
                            <Controller control={control} render={({field:{value, onChange}}) => (
                                <div className="col-12 md:col-6">
                                    <label htmlFor="birth">{text[lang].birth_date}</label>
                                    <Calendar
                                        locale={lang}
                                        dateFormat="dd/mm/yy"
                                        placeholder={text[lang].birth_date}
                                        className="w-full" value={value}
                                        onChange={(e) => onChange(e.value)}
                                    />
                                    {errors.birth && (
                                        <small className="form-element-is-invalid">
                                            {text[lang].field_required}
                                        </small>
                                    )}
                                </div>
                            )} name="birth"/>
                            <Controller control={control} render={({field:{value, onChange}}) => (
                                <div className="col-12 md:col-6">
                                    <label htmlFor="role">{text[lang].role}</label>
                                    <Controller control={control} render={({field:{value, onChange}}) => (
                                        <Dropdown
                                            value={value}
                                            onChange={onChange}
                                            options={roles}
                                            name="role"
                                            className="w-full" />
                                    )} name="role"/>
                                    {errors.role && (
                                        <small className="form-element-is-invalid">
                                            {text[lang].field_required}
                                        </small>
                                    )}
                                </div>
                            )} name="role"/>
                        </div>
                        <div className="w-full md:mt-5 mt-0">
                            <Controller control={control} render={({field:{value, onChange}}) => (
                                <div className="col-12">
                                    <label htmlFor="note">{text[lang].note}</label>
                                    <InputTextarea
                                        className="w-full"
                                        value={value}
                                        onChange={onChange}
                                        id="note"
                                        aria-describedby="note"
                                        placeholder={text[lang].note}
                                    />
                                    {errors.note && (
                                        <small className="form-element-is-invalid">
                                            {text[lang].field_required}
                                        </small>
                                    )}
                                </div>
                            )} name="note"/>
                        </div>
                        <div className="grid">
                            <div className="col-12 md:col-6">
                                <label htmlFor="birth">{text[lang].educations}</label>
                                {educationArray.fields.map((item, index) => (
                                    <div className="flex gap-2 mb-2 align-items-start" key={index}>
                                        <div className="flex flex-column gap-2 w-full">
                                            <Controller
                                                render={({field:{value, onChange}}) => (
                                                    <InputText
                                                        className="w-full"
                                                        placeholder={text[lang].year}
                                                        value={value}
                                                        onChange={onChange}
                                                    />
                                                )}
                                                name={`educations.${index}.years`}
                                                control={control}
                                            />
                                            <Controller
                                                render={({field:{value, onChange}}) => (
                                                    <InputTextarea
                                                        className="w-full"
                                                        value={value}
                                                        onChange={onChange}
                                                        placeholder={text[lang].desc}
                                                    />
                                                )}
                                                name={`educations.${index}.body`}
                                                control={control}
                                            />
                                        </div>
                                        <Button type="button" className="p-button-danger" onClick={() => {
                                            if (index === 0) {
                                                educationArray.append({
                                                    years:null,
                                                    body:null
                                                })
                                            } else {
                                                educationArray.remove(index)
                                            }
                                        }}>
                                            <i className={classNames({
                                                pi:true,
                                                'pi-plus':index === 0,
                                                'pi-minus':index !== 0
                                            })} style={{fontSize:'10px'}}/>
                                        </Button>
                                    </div>
                                ))}
                            </div>
                            <div className="col-12 md:col-6">
                                <label htmlFor="birth">{text[lang].works}</label>
                                {worksArray.fields.map((item, index) => (
                                    <div className="flex gap-2 mb-2 align-items-start" key={index}>
                                        <div className="flex flex-column gap-2 w-full">
                                            <Controller
                                                render={({field:{value, onChange}}) => (
                                                    <InputText
                                                        className="w-full"
                                                        placeholder={text[lang].year}
                                                        value={value}
                                                        onChange={onChange}
                                                    />
                                                )}
                                                name={`works.${index}.years`}
                                                control={control}
                                            />
                                            <Controller
                                                render={({field:{value, onChange}}) => (
                                                    <InputTextarea
                                                        className="w-full"
                                                        value={value}
                                                        onChange={onChange}
                                                        placeholder={text[lang].desc}
                                                    />
                                                )}
                                                name={`works.${index}.body`}
                                                control={control}
                                            />
                                        </div>
                                        <Button type="button" className="p-button-danger" onClick={() => {
                                            if (index === 0) {
                                                worksArray.append({
                                                    years:null,
                                                    body:null
                                                })
                                            } else {
                                                worksArray.remove(index)
                                            }
                                        }}>
                                            <i className={classNames({
                                                pi:true,
                                                'pi-plus':index === 0,
                                                'pi-minus':index !== 0
                                            })} style={{fontSize:'10px'}}/>
                                        </Button>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="grid">
                            <div className="col-12">
                                <div className="flex justify-content-end">
                                    <Button className="p-button-danger d-flex align-items-center gap-1" disabled={loader}>
                                        {loader && <i className="pi pi-spin pi-spinner mr-1"/>}
                                        {text[lang].save}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            )}
        </div>
    )
}

export default Profile
