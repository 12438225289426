import axios from './index'

class Auth {
    getUser = () => axios.get('/auth')
    login = data => axios.post('/auth', data)
    register = data => axios.post('/register', data)
    userUpdate = data => axios.post('/user/update', data)
    logout = () => axios.delete('/user/logout')
    findUnCompletedUser = params => axios.get('/user/findUncompletedUser', {params})
    verifyAccount = data => axios.post('/user/verifyEmail', data)
}

export default new Auth()
