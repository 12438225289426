import {Controller, useForm} from 'react-hook-form'
import text from '../../data/text.json'
import {InputText} from 'primereact/inputtext'
import {Button} from 'primereact/button'
import {useEffect, useState} from 'react'
import {Dropdown} from 'primereact/dropdown'
import {Calendar} from 'primereact/calendar'
import Patient from '../../api/patient'
import {InputTextarea} from 'primereact/inputtextarea'
import moment from 'moment'
import {Card} from 'primereact/card'
import {Checkbox} from 'primereact/checkbox'
import {MultiSelect} from 'primereact/multiselect'

const PatientForm = ({modal, setModal, doctors, fetchData}) => {
    const lang = 'az'
    const [loader, setLoader] = useState(false)
    const [doctorDates, setDoctorDates] = useState([])
    const [searchedData, setSearchedData] = useState([])
    const prefixElement = prefix => {
        return {
            value : prefix,
            label : prefix
        }
    }
    const prefixes = [
        prefixElement('050'),
        prefixElement('051'),
        prefixElement('055'),
        prefixElement('070'),
        prefixElement('077'),
        prefixElement('099'),
        prefixElement('010'),
        prefixElement('060')
    ]

    const {control, handleSubmit, setValue, reset, watch, formState:{errors}}  = useForm({
        defaultValues:{
            phone_prefix:prefixes[0].value
        }
    })

    const statuses = [
        {
            value:-1,
            label:text[lang].patient_not_join
        },
        {
            value:-2,
            label:text[lang].doctor_not_join
        },
        {
            value: 1,
            label: text[lang].pending
        },
        {
            value: 2,
            label: text[lang].accept
        }
    ]

    const create = async data => {
        setLoader(true)
        try {
            if (data?.not_work) {
                await Patient.addMeeting({
                    not_work:1,
                    date:moment(data?.date).format('YYYY-MM-DD'),
                    doctor_id:data?.doctor_id,
                    hour:data?.hour
                })
                fetchData()
                setModal(false)
                return
            }
            const birth = data?.birth ? moment(data?.birth).format('YYYY-MM-DD') : null
            if (modal?.id) {
                await Patient.updateMeeting({
                    ...data,
                    date:moment(data?.date).format('YYYY-MM-DD'),
                    birth
                })
            } else {
                await Patient.addMeeting({
                    ...data,
                    date:moment(data?.date).format('YYYY-MM-DD'),
                    birth
                })
            }
            fetchData()
            setModal(false)
        } catch (e) {
            console.log('Error')
        } finally {
            setLoader(false)
        }
    }

    const doctorId = watch('doctor_id')
    const date = watch('date')
    const name = watch('name')
    const surname = watch('surname')
    const phonePrefix = watch('phone_prefix')
    const phone = watch('phone')
    const getDoctorHours = async() => {
        setDoctorDates([])
        const data = await Patient.getDoctorHours({
            doctor_id:doctorId,
            date:moment(date).format('YYYY-MM-DD')
        })
        setDoctorDates(data)
    }

    const findPatient = async() => {
        if (name || surname || phone) {
            const data = await Patient.findPatient({
                name,
                surname,
                phone,
                phone_prefix:phone ? phonePrefix : null
            })
            setSearchedData(data)
        }
    }

    const selectPatient = id => {
        const patient = searchedData?.find(item => item?.id === id)
        setValue('name', patient?.name)
        setValue('surname', patient?.surname)
        setValue('phone', patient?.phone)
        setValue('phone_prefix', patient?.phone_prefix)
        setValue('birth', new Date(patient?.birth))
    }

    const notWork = watch('not_work')

    useEffect(() => {
        findPatient()
    }, [name, surname, phone, phonePrefix])

    useEffect(() => {
        if (doctorId && date) {
            getDoctorHours()
        }
    }, [doctorId, date])

    useEffect(() => {
        reset({
            ...modal,
            birth:modal?.birth ? new Date(modal?.birth) : null,
            hour:[modal?.hour]
        })
    }, [modal])
    return (
        <form onSubmit={handleSubmit(create)} className="grid w-full patient-form">
            {!modal?.id && searchedData.length > 0 && (
                searchedData.map(item => (
                    <div className="col-12" key={item?.id} onClick={() => selectPatient(item?.id)}>
                        <Card>
                            {item?.name} {item?.surname} ({item?.phone_prefix} {item?.phone})
                        </Card>
                    </div>
                ))
            )}
            <Controller render={({field:{value, onChange}}) => (
                <div className="col-12">
                    <div className="flex align-items-center">
                        <Checkbox inputId="notWork" name="not_work" value={value} onChange={onChange} checked={value === 1 || value === true} />
                        <label htmlFor="notWork" className="ml-2">{text[lang].i_am_not_join}</label>
                    </div>
                </div>
            )} name="not_work" control={control}/>
            {!notWork ? (
                <>
                    <Controller rules={{
                        required:true
                    }} control={control} render={({field:{value, onChange}}) => (
                        <div className="col-12 md:col-6">
                            <label htmlFor="name">{text[lang].name}</label>
                            <InputText
                                className="w-full"
                                value={value}
                                onChange={onChange}
                                id="name"
                                aria-describedby="name"
                                placeholder={text[lang].name}
                            />
                            {errors.name && (
                                <small className="form-element-is-invalid">
                                    {text[lang].field_required}
                                </small>
                            )}
                        </div>
                    )} name="name"/>
                    <Controller rules={{
                        required:true
                    }} control={control} render={({field:{value, onChange}}) => (
                        <div className="col-12 md:col-6">
                            <label htmlFor="surname">{text[lang].surname}</label>
                            <InputText
                                className="w-full"
                                value={value}
                                onChange={onChange}
                                id="surname"
                                aria-describedby="surname"
                                placeholder={text[lang].surname}
                            />
                            {errors.surname && (
                                <small className="form-element-is-invalid">
                                    {text[lang].field_required}
                                </small>
                            )}
                        </div>
                    )} name="surname"/>
                    <div className="col-12 md:col-6">
                        <label htmlFor="phone_prefix">{text[lang].mobile_phone}</label>
                        <div className="w-full flex align-items-start gap-1">
                            <Controller control={control} render={({field:{value, onChange}}) => (
                                <Dropdown
                                    value={value}
                                    onChange={onChange}
                                    options={prefixes}
                                    name="phone_prefix"
                                    className="w-14" />
                            )} name="phone_prefix"/>
                            <div className="w-full">
                                <Controller render={({field:{value, onChange}}) => (
                                    <InputText
                                        className="w-full"
                                        value={value}
                                        onChange={onChange}
                                        id="phone"
                                        aria-describedby="phone"
                                        placeholder={text[lang].mobile_phone}
                                    />
                                )} name="phone" control={control}/>
                                {errors.phone && (
                                    <small className="form-element-is-invalid">
                                        {text[lang].field_required}
                                    </small>
                                )}
                            </div>
                        </div>
                    </div>
                    <Controller control={control} render={({field:{value, onChange}}) => (
                        <div className="col-12 md:col-6">
                            <label htmlFor="birth">{text[lang].birth_date}</label>
                            <Calendar
                                locale={lang}
                                dateFormat="dd/mm/yy"
                                placeholder={text[lang].birth_date} className="w-full" value={value} onChange={(e) => onChange(e.value)} />
                            {errors.birth && (
                                <small className="form-element-is-invalid">
                                    {text[lang].field_required}
                                </small>
                            )}
                        </div>
                    )} name="birth"/>
                    <div className="col-12 md:col-6">
                        <label htmlFor="doctor_id">{text[lang].doctor}</label>
                        <Controller rules={{required:true}} control={control} render={({field:{value, onChange}}) => (
                            <Dropdown
                                value={value}
                                onChange={onChange}
                                options={doctors}
                                name="doctor_id"
                                className="w-full" />
                        )} name="doctor_id"/>
                        {errors.doctor_id && (
                            <small className="form-element-is-invalid">
                                {text[lang].field_required}
                            </small>
                        )}
                    </div>
                    <Controller rules={{
                        required:true
                    }} control={control} render={({field:{value, onChange}}) => (
                        <div className="col-12 md:col-6">
                            <label htmlFor="date">{text[lang].date}</label>
                            <Calendar
                                locale={lang}
                                dateFormat="dd/mm/yy"
                                placeholder={text[lang].date} className="w-full" value={value} onChange={(e) => onChange(e.value)} />
                            {errors.date && (
                                <small className="form-element-is-invalid">
                                    {text[lang].field_required}
                                </small>
                            )}
                        </div>
                    )} name="date"/>
                    {doctorId && date && (
                        <div className="col-12 md:col-6">
                            <label htmlFor="hour">{text[lang].hour}</label>
                            <Controller rules={{
                                required:true
                            }} control={control} render={({field:{value, onChange}}) => (
                                <MultiSelect
                                    value={value}
                                    onChange={onChange}
                                    multiple={true}
                                    options={doctorDates}
                                    optionDisabled={e => e.is_disabled}
                                    name="hour"
                                    className="w-full" />
                            )} name="hour"/>
                            {errors.hour && (
                                <small className="form-element-is-invalid">
                                    {text[lang].field_required}
                                </small>
                            )}
                        </div>
                    )}
                    <Controller control={control} render={({field:{value, onChange}}) => (
                        <div className="col-12 md:col-6">
                            <label htmlFor="insurance">{text[lang].insurance}</label>
                            <InputText
                                className="w-full"
                                value={value}
                                onChange={onChange}
                                id="insurance"
                                aria-describedby="insurance"
                                placeholder={text[lang].insurance}
                            />
                            {errors.insurance && (
                                <small className="form-element-is-invalid">
                                    {text[lang].field_required}
                                </small>
                            )}
                        </div>
                    )} name="insurance"/>
                    <Controller control={control} render={({field:{value, onChange}}) => (
                        <div className="col-12 md:col-6">
                            <label htmlFor="note">{text[lang].note}</label>
                            <InputTextarea
                                className="w-full"
                                value={value}
                                onChange={onChange}
                                id="note"
                                aria-describedby="note"
                                placeholder={text[lang].note}
                            />
                            {errors.note && (
                                <small className="form-element-is-invalid">
                                    {text[lang].field_required}
                                </small>
                            )}
                        </div>
                    )} name="note"/>
                    {modal?.visible_participate_input && (
                        <Controller control={control} render={({field:{value, onChange}}) => (
                            <div className="col-12 md:col-6">
                                <label htmlFor="note">{text[lang].status}</label>
                                <Dropdown
                                    value={value}
                                    onChange={onChange}
                                    options={statuses}
                                    name="status"
                                    className="w-full" />
                                {errors.status && (
                                    <small className="form-element-is-invalid">
                                        {text[lang].field_required}
                                    </small>
                                )}
                            </div>
                        )} name="status"/>
                    )}
                </>
            ) : (
                <>
                    <div className="col-12 md:col-6">
                        <label htmlFor="doctor_id">{text[lang].doctor}</label>
                        <Controller rules={{required:true}} control={control} render={({field:{value, onChange}}) => (
                            <Dropdown
                                value={value}
                                onChange={onChange}
                                options={doctors}
                                name="doctor_id"
                                className="w-full" />
                        )} name="doctor_id"/>
                        {errors.doctor_id && (
                            <small className="form-element-is-invalid">
                                {text[lang].field_required}
                            </small>
                        )}
                    </div>
                    <Controller rules={{
                        required:true
                    }} control={control} render={({field:{value, onChange}}) => (
                        <div className="col-12 md:col-6">
                            <label htmlFor="date">{text[lang].date}</label>
                            <Calendar
                                locale={lang}
                                dateFormat="dd/mm/yy"
                                placeholder={text[lang].date} className="w-full" value={value} onChange={(e) => onChange(e.value)} />
                            {errors.date && (
                                <small className="form-element-is-invalid">
                                    {text[lang].field_required}
                                </small>
                            )}
                        </div>
                    )} name="date"/>
                    {doctorId && date && (
                        <div className="col-12 md:col-6">
                            <label htmlFor="hour">{text[lang].hour}</label>
                            <Controller rules={{
                                required:true
                            }} control={control} render={({field:{value, onChange}}) => (
                                <MultiSelect
                                    value={value}
                                    onChange={onChange}
                                    multiple={true}
                                    options={doctorDates}
                                    optionDisabled={e => e.is_disabled}
                                    name="hour"
                                    className="w-full" />
                            )} name="hour"/>
                            {errors.hour && (
                                <small className="form-element-is-invalid">
                                    {text[lang].field_required}
                                </small>
                            )}
                        </div>
                    )}
                </>
            )}
            <div className="col-12">
                <div className="flex justify-content-end gap-2">
                    <Button className="p-button-outlined p-button-sm" onClick={e => {
                        e.preventDefault()
                        setModal(null)
                    }}>{text[lang].close}</Button>
                    <Button className="p-button-danger p-button-sm" disabled={loader}>
                        {loader && <i className="pi pi-spin pi-spinner mr-1"/>}
                        {text[lang].save}
                    </Button>
                </div>
            </div>
        </form>
    )
}

export default PatientForm
