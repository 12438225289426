import {Controller, useForm} from 'react-hook-form'
import text from '../../data/text.json'
import {InputText} from 'primereact/inputtext'
import {TreeSelect} from 'primereact/treeselect'
import {Button} from 'primereact/button'
import {useState} from 'react'
import Site from '../../api/site'

const ServiceModal = ({fetchData, formIsActive, setFormIsActive, services}) => {
    const lang = 'az'
    const [loader, setLoader] = useState(false)
    const {control, handleSubmit} = useForm({
        defaultValues:{...formIsActive, name:formIsActive?.label}
    })

    const update = async(data) => {
        try {
            await Site.updateService(data)
            fetchData()
            setFormIsActive(false)
        } catch (e) {
            console.log(e)
        } finally {
            setLoader(false)
        }
    }

    return (
        <form onSubmit={handleSubmit(update)} className="w-full">
            <div className="grid">
                <div className="col-12">
                    <label htmlFor="name">{text[lang].name}</label>
                    <Controller render={({field:{value, onChange}}) => (
                        <InputText
                            className="w-full"
                            value={value}
                            onChange={onChange}
                            id="name"
                            aria-describedby="name"
                            placeholder={text[lang].name}
                        />
                    )} name="name" control={control}/>
                </div>
                <div className="col-12">
                    <Controller control={control} render={({field:{value, onChange}}) => (
                        <>
                            <label htmlFor="note">{text[lang].parent_service}</label>
                            <TreeSelect
                                id="parent_id"
                                value={value}
                                onChange={onChange}
                                options={services}
                                name="parent_id"
                                selectionMode="single"
                                className="w-full" />
                        </>
                    )} name="parent_id"/>
                </div>
            </div>
            <div className="grid">
                <div className="col-12">
                    <div className="mt-2 flex justify-content-end">
                        <Button disabled={loader} className="p-button-danger">
                            {loader ? <i className="pi pi-spin pi-spinner mr-1"/> : text[lang].save}
                        </Button>
                    </div>
                </div>
            </div>
        </form>
    )
}

export default ServiceModal
